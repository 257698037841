export const socialsData = {
  github: 'https://github.com/TalhaZulfiqar69',
  // facebook: 'https://www.facebook.com/',
  linkedIn:
    'https://pk.linkedin.com/in/talha-zulfiqar-176b42121?trk=people-guest_people_search-card',
  instagram: 'https://www.instagram.com/talha_zee69/',
  // codepen: 'https://codepen.io/',
  // twitter: 'https://twitter.com/',
  // reddit: 'https://www.reddit.com/user/',
  // blogger: 'https://www.blogger.com/',
  // medium: 'https://medium.com/@',
  // stackOverflow: 'https://stackoverflow.com/users/',
  //   gitlab: 'https://gitlab.com/',
  //   youtube: 'https://youtube.com/',
};
