export const experienceData = [
  {
    id: 1,
    company: 'Bluehorn Tech',
    jobtitle: 'Laravel Developer',
    startYear: 'Aug 2018',
    endYear: 'Jul 2019',
  },
  {
    id: 2,
    company: 'Programmers Force',
    jobtitle: 'Laravel Developer',
    startYear: 'Aug 2019',
    endYear: 'Sep 2020',
  },
  {
    id: 3,
    company: 'Invozone',
    jobtitle: 'Software Engineer',
    startYear: 'Sep 2020',
    endYear: 'Present',
  },
];
