/* eslint-disable */
import { BiShoppingBag, BiPencil } from 'react-icons/bi';
import { BsCodeSlash, BsClipboardData } from 'react-icons/bs';
import { AiOutlineMail, AiFillAudio } from 'react-icons/ai';
import {
  FaInternetExplorer,
  FaChalkboardTeacher,
  FaCameraRetro,
  FaPinterest,
  FaVideo,
  FaTabletAlt,
  FaRegNewspaper,
} from 'react-icons/fa';

import { SiBandrautomation, SiBlockchaindotcom } from 'react-icons/si';

import { MdOutlineDesignServices } from 'react-icons/md';

export const servicesData = [
  // {
  //     id: 1,
  //     title: 'Marketing',
  //     icon: <BiShoppingBag/>
  // },
  //   {
  //     id: 2,
  //     title: 'Content Writing',
  //     icon: <BiPencil />,
  //   },
  //   {
  //     id: 3,
  //     title: 'Email Marketing',
  //     icon: <AiOutlineMail />,
  //   },
  // {
  //     id: 4,
  //     title: 'Internet Research',
  //     icon: <FaInternetExplorer />
  // },
  // {
  //     id: 5,
  //     title: 'Tutoring',
  //     icon: <FaChalkboardTeacher />
  // },

  {
    id: 6,
    title: 'Web Design',
    icon: <MdOutlineDesignServices />,
  },
  {
    id: 7,
    title: 'Web Development',
    icon: <BsCodeSlash />,
  },
  // {
  //     id: 8,
  //     title: 'Pinterest Virtual Assistant',
  //     icon: <FaPinterest />
  // },
  {
    id: 9,
    title: 'Automation Testing',
    icon: <SiBandrautomation />,
  },
  // {
  //     id: 10,
  //     title: 'Data Entry',
  //     icon: <BsClipboardData />
  // },
  {
    id: 11,
    title: 'Solana blockchain',
    icon: <SiBlockchaindotcom />,
  },
  // {
  //     id: 12,
  //     title: 'Audio Transcription',
  //     icon: <AiFillAudio />
  // },
  {
    id: 13,
    title: 'Web3 Integration',
    icon: <FaRegNewspaper />,
  },
];

// Uncomment your required service.
// Couldn't find the required services? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new
// You can also add on your own 😉.
