export const educationData = [
  {
    id: 1,
    institution: 'Lahore Board',
    course: 'Higher Secondary Education',
    startYear: '2012',
    endYear: '2014',
  },
  {
    id: 2,
    institution: 'Virtual university of Pakistan',
    course: 'Bachelor of science and Information Technology',
    startYear: '2014',
    endYear: '2018',
  },
  {
    id: 3,
    institution: 'Częstochowa University of Technology',
    course: 'Master of data sciences computational intelligance',
    startYear: '2022',
    endYear: 'Present',
  },
];
