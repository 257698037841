/* eslint-disable */
import {
  greenThemeLight,
  greenThemeDark,
  bwThemeLight,
  bwThemeDark, // awesome
  blueThemeLight, // this
  blueThemeDark,
  redThemeLight,
  redThemeDark,
  orangeThemeLight,
  orangeThemeDark,
  purpleThemeLight,
  purpleThemeDark,
  pinkThemeLight,
  pinkThemeDark,
  yellowThemeLight,
  yellowThemeDark,
} from '../theme/theme';

export const themeData = {
  // theme: blueThemeLight,
  theme:orangeThemeDark
};

// Choose theme from above
