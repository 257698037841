import resume from '../assets/pdf/resume.pdf';
import talhazee from '../assets/jpeg/TalhaZee.jpeg';

export const headerData = {
  name: 'Muhammad Talha Zulfiqar',
  title: 'Software Engineer',
  desciption:
    "A detail-oriented, passionate and self-motivated software engineer and programming enthusiast with an ability to ideate, create and transform ideas into code. I am passionate about giving shape to my ideas. Being a programming enthusiast I'm always looking to learn and explore different programming languages. Being from a technical background, I can understand requirement briefs and deliver great results accordingly. My ambition is to work in an organized and proactive organization where my passion and skills combined with strategy. My major interested domains are Mean/Mern stack development and technical writing.",
  image:talhazee,
  resumePdf: resume,
};
