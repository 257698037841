import one from '../assets/svg/projects/one.svg';
import two from '../assets/svg/projects/two.svg';
import three from '../assets/svg/projects/three.svg';
import four from '../assets/svg/projects/four.svg';
import five from '../assets/svg/projects/five.svg';
import six from '../assets/svg/projects/six.svg';
import seven from '../assets/svg/projects/seven.svg';
import eight from '../assets/svg/projects/eight.svg';

export const projectsData = [
  {
    id: 1,
    projectName: 'The nutrition expert directory for health & wellness brands',
    projectDesc:
      'TThe RD Link which is the only one-stop-shop platform to connect RDs and brands to ideate and execute hundreds of partnerships.',
    tags: ['React', 'Tailwind Css', 'Php Laravel', 'Sockets'],
    // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    demo: 'https://therdlink.com/',
    image: one,
  },
  {
    id: 2,
    projectName: 'Quiz App',
    projectDesc:
      'Recognize React Reduce is Stroke Prevention and Practical Considerations for Patients with AF and Diabetes.',
    tags: ['React', 'Material Ui', 'Node Js'],
    // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    demo: 'https://rrr-cme.ca/',
    image: two,
  },
  {
    id: 3,
    projectName: 'Artefy',
    projectDesc:
      'Digital collectables are as varied as the passions, lifestyles and hobbies from which they come. Find works, and communities that relate to you. From fantasy art to music to sport or gaming, wherever your passion lives, Artefy has you covered.',
    tags: ['React', 'Node', 'Material Ui', 'Web3', 'Polygon'],
    // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    demo: 'https://artefy.io/',
    image: three,
  },
  {
    id: 4,
    projectName: 'Solana Tiktok Dapp',
    projectDesc:
      'This project is based on solana blockchain. This is solana tiktok dapp. User can signup with digital wallet (phantom, solflare), create project, tiktok videos, comment and like on the videos.',
    tags: ['Next', 'Anchor/Rust', 'Solana Web3'],
    code: 'https://github.com/TalhaZulfiqar69/TikTok-Web-3.0-Blockchain-App-with-Solana',
    // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    image: four,
  },
  {
    id: 5,
    projectName: 'Solana Wallet Integration',
    projectDesc: 'A Simple E-commerce application',
    tags: ['React', 'Solana Web3'],
    code: 'https://github.com/TalhaZulfiqar69/solana-web3-wallet-connection',
    // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    image: five,
  },
  {
    id: 6,
    projectName: 'Uber Lite',
    projectDesc: 'Uber clone',
    tags: ['Flutter'],
    code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    image: six,
  },
  {
    id: 7,
    projectName: 'Stock Market App',
    projectDesc: 'A simple stock market API app',
    tags: ['React', 'Redux', 'Bootstrap'],
    code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    image: seven,
  },
  {
    id: 8,
    projectName: 'Car Pooling System',
    projectDesc:
      'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
    tags: ['Flutter', 'React'],
    code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    image: eight,
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
